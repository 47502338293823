import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_NAME } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454M_VERSION } from '../../../../operations/queries/forms/3454M'
import dayjs from 'dayjs'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Typography,
  Checkbox,
  Radio
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454M } from '../../../../operations/mutations/forms/3454M'
import Editor from '../../../elements/common/Editor'
import FormItem from 'antd/lib/form/FormItem'
import { PlusOutlined } from '@ant-design/icons'

const { Paragraph, Text } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)
  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    const evaluationMeasures = formFieldValues?.evaluationMeasures?.map(
      ({ __typename, ...props }) => props
    )
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      evaluationMeasures: evaluationMeasures
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? dayjs(fieldValue) : ''
        break
      case 'parentNotificationMethods':
        setParentNotificationOther(isOther(fieldValue.split(',')))
        update[fieldName] = fieldValue.split(',')
        break
      case 'evaluationMeasures':
        update[fieldName] = JSON.parse(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const parentNotificationMethods = [
    { label: 'Report Card', value: 'report-card' },
    { label: 'Progress Reports', value: 'progress-reports' },
    { label: 'Parent Conference', value: 'parent-conference' },
    { label: 'Other', value: 'other' }
  ]

  const goalOptions = [
    { label: 'Academic', value: 'academic' },
    { label: 'Functional', value: 'functional' },
    { label: 'Transition', value: 'transition' }
  ]

  const evaluationProcedureOptions = [
    { label: 'Observation Log', value: 'observation-log' },
    { label: 'Data Charts', value: 'data-charts' },
    { label: 'Tests', value: 'tests' },
    { label: 'Other (specify)', value: 'other' }
  ]

  const scheduleDeterminingProgressOptions = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Semester', value: 'semester' },
    { label: 'Other', value: 'other' }
  ]

  const isOther = values => {
    return values?.includes('other')
  }

  const [parentNotificationOther, setParentNotificationOther] = useState(
    isOther(data?.parentNotificationMethods)
  )
  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          validationProps,
          handleActionEvents
        }) => (
          <Form
            form={form}
            id='studentForm3454M'
            name='studentForm3454M'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              GOALS AND OBJECTIVES/BENCHMARKS
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='Reporting on Goals'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='The progress on annual goals will be measured by the short-term objectives/benchmarks. Check the methods that will be used to notify parents
                of the student’s progress on annual goals and if the progress is sufficient to achieve the goals by the end of the IEP year:'
                name='parentNotificationMethods'
                {...validationProps('parentNotificationMethods')}
              >
                <Checkbox.Group //Multi-select from: Report Card/Progress Reports/Parent Conference/Other
                  options={parentNotificationMethods}
                  onChange={checkedValues => {
                    console.log(checkedValues, checkedValues.toString())
                    setParentNotificationOther(isOther(checkedValues))
                    const e = {
                      target: {
                        name: 'parentNotificationMethods',
                        value: checkedValues.toString() //Pass all selected
                      }
                    }
                    handleMouseEvents.onChange(e)
                  }}
                  defaultValue={[]}
                  disabled={formDisabledState.parentNotificationMethods}
                />
              </Form.Item>
              <FormItem
                hidden={!parentNotificationOther}
                label='Other'
                name='parentNotificationMethodsOther'
                {...validationProps('parentNotificationMethodsOther')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.parentNotificationMethodsOther}
                />
              </FormItem>
            </Card>

            <Card
              title='Present Levels of Performance'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Indicate Goal Area'
                name='goalArea'
                {...validationProps('goalArea')}
              >
                <Checkbox.Group
                  options={goalOptions}
                  onChange={checkedValues => {
                    const e = {
                      target: {
                        name: 'goalArea',
                        value: checkedValues.toString() //Pass all selected
                      }
                    }
                    handleMouseEvents.onChange(e)
                  }}
                  defaultValue={[]}
                  disabled={formDisabledState.goalArea}
                />
              </Form.Item>
              <Form.Item
                label="Student's Present Level for Goal Area (Include strengths, areas of needed improvement, and results of current or district-wide and 
                  classroom assessments relevant to this goal)"
                name='initialEvaluationResults'
                {...validationProps('initialEvaluationResults')}
              >
                <Editor
                  formField='initialEvaluationResults'
                  disabled={formDisabledState.initialEvaluationResults}
                  {...handleEditorEvents}
                />
              </Form.Item>
            </Card>

            <Card title='Goals and Objectives/Benchmarks'>
              <p>
                <strong>
                  The goals and short-term objectives or benchmarks shall meet
                  the student's educational needs that result from the student's
                  disability, including involvement in and progress in general
                  curriculum. Consider participation in appropriate activities
                  when evaluating preschool students.
                </strong>
              </p>
              <FormItem
                label='Goal #'
                name='goalNumber'
                //todo, figure out why validation props breaks front end regex check but doesn't affect backend validation
                {...validationProps('goalNumber')}
                rules={[
                  {
                    pattern: /^[1-9]\d*$/,
                    message: 'Must be a positive number'
                  }
                ]}
              >
                <Input
                  type='number'
                  min='1'
                  onWheel={e => e.target.blur()}
                  {...handleFocusEvents}
                  disabled={formDisabledState.goalNumber}
                />
              </FormItem>
              <FormItem
                label='Total number of goals'
                name='goalTotal'

                {...validationProps('goalTotal')}
                //todo, figure out why validation props breaks front end regex check but doesn't affect backend validation
                rules={[
                  {
                    pattern: /^[1-9]\d*$/,
                    message: 'Must be a positive number'
                  }
                ]}
              >
                <Input
                  type='number'
                  min='1'
                  onWheel={e => e.target.blur()}
                  {...handleFocusEvents}
                  disabled={formDisabledState.goalTotal}
                />
              </FormItem>

              <FormItem
                label='Illinois Learning Standard #'
                name='illinoisLearningStandard'
                {...validationProps('illinoisLearningStandard')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.illinoisLearningStandard}
                />
              </FormItem>
              <Form.Item
                label='Goal Statement'
                name='goalStatement'
                {...validationProps('goalStatement')}
              >
                <Editor
                  formField='goalStatement'
                  disabled={formDisabledState.goalStatement}
                  {...handleEditorEvents}
                />
              </Form.Item>

              <FormItem
                label='Title(s) of Goal Implementer(s)'
                name='goalImplementers'
                {...validationProps('goalImplementers')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.goalImplementers}
                />
              </FormItem>

              <Input.Group
                onFocus={() => {
                  handleEditorEvents.onFocus('evaluationMeasures')
                }}
                onBlur={() => {
                  handleEditorEvents.onBlur(
                    'evaluationMeasures',
                    JSON.stringify(form.getFieldValue('evaluationMeasures'))
                  )
                }}
              >
                <Form.List
                  name='evaluationMeasures'
                  {...validationProps('evaluationMeasures')}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Card
                            title='Short-Term Objective/Benchmark for Measuring Progress on the Annual Goal'
                            style={{
                              marginBottom: '30px',
                              borderStyle: 'dashed'
                            }}
                          >
                            <Form.Item
                              {...field}
                              label='Short-Term Objective/Benchmark'
                              name={[field.name, 'shortTermObjective']}
                              key={[field.fieldKey, 'shortTermObjective']}
                              onFocus={e => {
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                              onBlur={e => {
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                            >
                              <Editor
                                disabled={formDisabledState.evaluationMeasures}
                                onFocus={e => {
                                  handleEditorEvents.onFocus(
                                    'evaluationMeasures'
                                  )
                                }}
                                onBlur={e => {
                                  handleEditorEvents.onBlur(
                                    'evaluationMeasures',
                                    JSON.stringify(
                                      form.getFieldValue('evaluationMeasures')
                                    )
                                  )
                                }}
                              />
                            </Form.Item>
                            <Card
                              title='Evaluation Criteria'
                              style={{ marginBottom: '30px' }}
                            >
                              <Form.Item
                                {...field}
                                label='Accuracy (%)'
                                name={[
                                  field.name,
                                  'evaluationCriteriaAccuracyPercent'
                                ]}
                                key={[
                                  field.fieldKey,
                                  'evaluationCriteriaAccuracyPercent'
                                ]}
                                rules={[
                                  {
                                    pattern: /^[1-9]\d*$/,
                                    message: 'Must be a positive number'
                                  }
                                ]}
                              >
                                <Input
                                  type='number'
                                  min='1'
                                  onWheel={e => e.target.blur()}
                                  disabled={
                                    formDisabledState.evaluationMeasures
                                  }
                                />
                              </Form.Item>

                              <Form.Item
                                {...field}
                                label='Attempt #'
                                name={[
                                  field.name,
                                  'evaluationCriteriaAttempts'
                                ]}
                                key={[
                                  field.fieldKey,
                                  'evaluationCriteriaAttempts'
                                ]}
                                rules={[
                                  {
                                    pattern: /^[1-9]\d*$/,
                                    message: 'Must be a positive number'
                                  }
                                ]}
                              >
                                <Input
                                  type='number'
                                  min='1'
                                  onWheel={e => e.target.blur()}
                                  disabled={
                                    formDisabledState.evaluationMeasures
                                  }
                                />
                              </Form.Item>

                              <Form.Item
                                {...field}
                                label='Total attempts #'
                                name={[
                                  field.name,
                                  'evaluationCriteriaTotalAttempts'
                                ]}
                                key={[
                                  field.fieldKey,
                                  'evaluationCriteriaTotalAttempts'
                                ]}
                                rules={[
                                  {
                                    pattern: /^[1-9]\d*$/,
                                    message: 'Must be a positive number'
                                  }
                                ]}
                              >
                                <Input
                                  type='number'
                                  min='1'
                                  onWheel={e => e.target.blur()}
                                  disabled={
                                    formDisabledState.evaluationMeasures
                                  }
                                />
                              </Form.Item>
                              <FormItem
                                label='Other'
                                name={[field.name, 'evaluationCriteriaOther']}
                                key={[
                                  field.fieldKey,
                                  'evaluationCriteriaOther'
                                ]}
                                onFocus={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                onBlur={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                              >
                                <Radio.Group
                                  buttonStyle='solid'
                                  disabled={
                                    formDisabledState.evaluationMeasures
                                  }
                                  onChange={e => {
                                    //Following is for testing get/set of fields nested in array:
                                    //  Value returned by dev tools element: studentForm3454M_evaluationMeasures_1_evaluationCriteriaOther (for second row in array
                                    //    const currentValue=form.getFieldValue("studentForm3454M_evaluationMeasures_1_evaluationCriteriaOther")  //Doesn't return anything
                                    //    Doesn't appear that you can use the element name directly in form.getFieldValue()
                                    //  Need to get array holding data, use field.key as row index to get array value
                                    //const evaluationMeasures = form.getFieldValue("evaluationMeasures");
                                    //const currentValue=evaluationMeasures[field.key].evaluationCriteriaOther;  //field value of currently selected row
                                    //console.log('current value using field name:',field.key,currentValue)
                                    handleEditorEvents.onBlur(
                                      'evaluationMeasures',
                                      JSON.stringify(
                                        form.getFieldValue('evaluationMeasures')
                                      )
                                    )
                                  }}
                                >
                                  <Radio.Button value='yes'>Yes</Radio.Button>
                                  <Radio.Button value='no'>No</Radio.Button>
                                </Radio.Group>
                              </FormItem>

                              <Form.Item
                                {...field}
                                label='Other'
                                name={[
                                  field.name,
                                  'evaluationCriteriaOtherText'
                                ]}
                                key={[
                                  field.fieldKey,
                                  'evaluationCriteriaOtherText'
                                ]}
                              >
                                <Input
                                  disabled={
                                    formDisabledState.evaluationMeasures
                                  }
                                />
                              </Form.Item>
                            </Card>

                            <Card
                              title='Evaluation Procedures'
                              style={{ marginBottom: '30px' }}
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, 'evaluationProcedures']}
                                key={[field.fieldKey, 'evaluationProcedures']}
                              >
                                <Checkbox.Group //Multi-select from: Observation Log/Data Charts/Tests/Other
                                  options={evaluationProcedureOptions}
                                  disabled={
                                    formDisabledState.evaluationMeasures
                                  }
                                  onMouseDown={e => e.preventDefault()} //This prevents onFocus from firing that causes double render(flash type look)
                                  onChange={checkedValues => {
                                    const evaluationMeasures =
                                      form.getFieldValue('evaluationMeasures')
                                    evaluationMeasures.evaluationProcedureOptions =
                                      checkedValues
                                    handleEditorEvents.onBlur(
                                      'evaluationMeasures',
                                      JSON.stringify(
                                        form.getFieldValue('evaluationMeasures')
                                      )
                                    )
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                {...field}
                                label='Other'
                                name={[
                                  field.name,
                                  'evaluationProcedureOtherText'
                                ]}
                                key={[
                                  field.fieldKey,
                                  'evaluationProcedureOtherText'
                                ]}
                              >
                                <Input
                                  disabled={
                                    formDisabledState.evaluationMeasures
                                  }
                                />
                              </Form.Item>
                            </Card>

                            <Card
                              title='Schedule for Determining Progress'
                              style={{ marginBottom: '30px' }}
                            >
                              <Form.Item
                                {...field}
                                name={[
                                  field.name,
                                  'scheduleDeterminingProgress'
                                ]}
                                key={[
                                  field.fieldKey,
                                  'scheduleDeterminingProgress'
                                ]}
                              >
                                <Checkbox.Group //Single selection from: Daily/Weekly/Quarterly/Semester/Other
                                  options={scheduleDeterminingProgressOptions}
                                  disabled={
                                    formDisabledState.evaluationMeasures
                                  }
                                  onChange={checkedValues => {
                                    const evaluationMeasures =
                                      form.getFieldValue('evaluationMeasures') //Get evaluation measures (e.g. objectives/benchmarks array)
                                    evaluationMeasures[
                                      field.key
                                    ].scheduleDeterminingProgress = [''] //Clear existing selections for current row(objective)
                                    evaluationMeasures[
                                      field.key
                                    ].scheduleDeterminingProgress =
                                      checkedValues[0] //Only a single selection is allowed
                                    handleEditorEvents.onBlur(
                                      //Collaborate
                                      'evaluationMeasures',
                                      JSON.stringify(evaluationMeasures)
                                    )
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                {...field}
                                label='Other'
                                name={[
                                  field.name,
                                  'scheduleDeterminingProgressOtherText'
                                ]}
                                key={[
                                  field.fieldKey,
                                  'scheduleDeterminingProgressOtherText'
                                ]}
                              >
                                <Input
                                  disabled={
                                    formDisabledState.evaluationMeasures
                                  }
                                />
                              </Form.Item>
                            </Card>

                            <Card
                              title='Dates Reviewed/Extent of Progress'
                              style={{ marginBottom: '30px' }}
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, 'datesReviewed']}
                                key={[field.fieldKey, 'datesReviewed']}
                                onFocus={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                onBlur={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                              >
                                <Editor
                                  disabled={
                                    formDisabledState.evaluationMeasures
                                  }
                                  onFocus={e => {
                                    handleEditorEvents.onFocus(
                                      'evaluationMeasures'
                                    )
                                  }}
                                  onBlur={e => {
                                    handleEditorEvents.onBlur(
                                      'evaluationMeasures',
                                      JSON.stringify(
                                        form.getFieldValue('evaluationMeasures')
                                      )
                                    )
                                  }}
                                />
                              </Form.Item>
                            </Card>

                            <Button
                              danger
                              disabled={formDisabledState.evaluationMeasures}
                              onClick={() => {
                                remove(field.name)
                                handleEditorEvents.onBlur(
                                  'evaluationMeasures',
                                  JSON.stringify(
                                    form.getFieldValue('evaluationMeasures')
                                  )
                                )
                              }}
                            >
                              Remove Objective/Benchmark
                            </Button>
                          </Card>
                        </div>
                      ))}

                      <Form.Item>
                        {
                          // Default value below prevents Quill from breaking the render
                        }
                        <Button
                          disabled={formDisabledState.evaluationMeasures}
                          type='dashed'
                          onClick={() =>
                            add({
                              shortTermObjective: '',
                              evaluationCriteriaAccuracyPercent: '',
                              evaluationCriteriaAttempts: '',
                              evaluationCriteriaTotalAttempts: '',
                              evaluationCriteriaOther: 'no',
                              evaluationCriteriaOtherText: '',
                              evaluationProcedures: [],
                              evaluationProcedureOtherText: '',
                              scheduleDeterminingProgress: [],
                              scheduleDeterminingProgressOtherText: '',
                              datesReviewed: ''
                            })
                          }
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Objective/Benchmark
                        </Button>
                      </Form.Item>
                      <Text type='warning'>
                        {validationProps('evaluationMeasures').help}
                      </Text>
                    </>
                  )}
                </Form.List>
              </Input.Group>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454M}
              extractDataProp='printStudentForm3454M'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454M = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_NAME,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454M_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )
  //console.log("StudentForm3454M-data returned by query: ", studentFormId, formData)
  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}
    //console.log("Form3454-studentInfo: ", studentInfo)
    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454M: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? dayjs(formDate) : dayjs(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }
    //console.log("StudentForm3454M-initialValues: ", initialValues)
    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454M
